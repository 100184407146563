.LetterThree {
  @include letter;

  @include sm-only {
    font-size: 2rem;
    text-align: center;
  }
}

.LetterThree-stamp {
  vertical-align: middle;
  max-width: 8rem;

  @include md {
    max-width: 10rem;
  }
}

.LetterThree-stamp--tall {
  max-width: 6rem;

  @include md {
    max-width: 9rem;
  }
}

.LetterThree-stamp--square {
  max-width: 7rem;

  @include md {
    max-width: 12rem;
  }
}

.LetterThree-stamp--landscape {
  max-width: 11rem;

  @include md {
    max-width: 13rem;
  }
}

.LetterThree-block {
  margin-bottom: 3rem;

  @include md {
    margin-bottom: 4rem;
  }
}

.LetterThree-block--double {
  display: flex;
  align-items: center;
  justify-content: center;

  @include md {
    justify-content: flex-end;
  }

  .LetterThree-stamp {
    @include sm-only {
      max-width: 9rem;
    }
  }
}

.LetterThree-doubleItem {
  align-items: center;
  display: flex;
  flex-basis: calc(100% - 1rem);
  flex-wrap: wrap;
  justify-content: center;
  max-width: 15rem;

  &:first-child {
    margin-right: 2rem;
  }

  @include md {
    flex-basis: 50%;
    max-width: 100%;
  }
}

.LetterThree-doubleItemText {
  flex-basis: 100%;

  @include md {
    flex-basis: 50%;
    text-align: right;
    padding-right: 1rem;
  }
}

.LetterThree-doubleItemText--narrow {
  @include md {
    flex-basis: 35%;
  }
}

.LetterThree-doubleItemTextBottom {
  flex-basis: 100%;

  @include md {
    flex-basis: auto;
    text-align: left;
    padding-left: 0.5rem;
  }
}


.LetterThree-block--two {
  @include md {
    text-align: right;
    padding-right: 10%;
  }
}

.LetterThree-block--four {
  @include md {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.LetterThree-blockFourStamps {
  @include md {
    margin-left: 2rem;
  }
}

.LetterThree-bottom {
  margin: 4rem auto 0;

  @include md {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
  }

  @include lg {
    margin-top: 6rem;
  }
}

.LetterThree-bottomImages {
  position: relative;
  margin: 1rem auto 0;
  max-width: 32rem;

  @include md {
    margin: 0;
  }
}

.LetterThree-stamp--bottom {
  position: absolute;
  top: 10%;
  right: 10%;
  z-index: 0;
}

.LetterThree-tadija {
  position: relative;
  z-index: 0;

  width: 100%;
}
