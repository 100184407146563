@import url('https://fonts.googleapis.com/css?family=Patrick+Hand+SC&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Pangolin&display=swap');

img {
  max-width: 100%;
  width: auto;
}

body {
  background: $gray-light;
}

* {
  outline: none;
}

.--show-focus *:focus {
  outline: 1px dotted rgba(0, 0, 0, 0.7);
  outline-offset: 0.5rem;
}

.Content {
  background: $white;
  overflow: hidden;
}

.Container {
  @include container;
}

.NotFound {
  @include response;

  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  a {
    margin-top: 3rem;
    display: inline-block;
  }
}
