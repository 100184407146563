.Response {
  @include response;

  h1 {
    font-size: 1.5em;
    margin-bottom: 1em;
  }

  p {
    margin-bottom: 0.5em;
    line-height: 1.4em;
    text-indent: 2em;
  }
}

.Response-date {
  text-indent: 2em;
  margin-bottom: 3rem;
}

.Response-signature {
  margin-top: 2em;
  text-indent: 2em;
}

p.Response-center {
  text-indent: 0;
  text-align: center;
}
