html {
  font-size: 10px;

  @include xl {
    font-size: 12px;
  }
}

body {
  // -moz-osx-font-smoothing: grayscale;
  @include typewriter-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  font-weight: normal;
  margin: 0;
}

p {
  margin: 0;
}

a {
  @include link;
  transition: background 300ms, color 300ms, border 300ms;
  text-decoration: none;
}

hr {
  border: none;
  border-top: thin solid $gray-border;
  margin: 4rem auto;
  width: 10rem;
  position: relative;

  @include md {
    margin: 5rem auto;
  }

  @include lg {
    margin: 6rem auto;
  }
}
