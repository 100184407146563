.Footnote {
  font-size: 0.75em;
  margin-top: 4rem;

  h2 {
    clip: rect(0 0 0 0);
    opacity: 0;
    position: absolute;
    visibility: hidden;
  }
}

.Footnote-index {
  margin-right: 0.5rem;
}
