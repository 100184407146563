@import "https://fonts.googleapis.com/css?family=Patrick+Hand+SC&display=swap&subset=latin-ext";
@import "https://fonts.googleapis.com/css?family=Roboto+Slab:300&display=swap&subset=latin-ext";
@import "https://fonts.googleapis.com/css?family=Pangolin&display=swap";
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

progress {
  vertical-align: baseline;
}

template, [hidden] {
  display: none;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

a:active, a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 100%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  max-width: 100%;
  border-style: none;
}

video {
  max-width: 100%;
}

svg:not(:root) {
  overflow: hidden;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

button, input, select, textarea {
  font: inherit;
  margin: 0;
}

optgroup {
  font-weight: bold;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

img {
  max-width: 100%;
  width: auto;
}

body {
  background: #f7f7f7;
}

* {
  outline: none;
}

.--show-focus :focus {
  outline-offset: .5rem;
  outline: 1px dotted #000000b3;
}

.Content {
  background: #fff;
  overflow: hidden;
}

.Container {
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (min-width: 600px) {
  .Container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.NotFound {
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (min-width: 600px) {
  .NotFound {
    padding-top: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media (min-width: 900px) {
  .NotFound {
    padding-top: 6rem;
  }
}

.NotFound h1 {
  margin-bottom: 1rem;
  font-size: 3rem;
}

.NotFound a {
  margin-top: 3rem;
  display: inline-block;
}

html {
  font-size: 10px;
}

@media (min-width: 1200px) {
  html {
    font-size: 12px;
  }
}

body {
  font-family: Roboto Slab, Helvetica, Arial, sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.4em;
}

@media (min-width: 600px) {
  body {
    font-size: 2rem;
  }
}

@media (min-width: 900px) {
  body {
    font-size: 2.2rem;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-size: 1em;
  font-weight: normal;
}

p {
  margin: 0;
}

a {
  color: #111;
  border-bottom: thin solid #ddd;
  text-decoration: none;
  transition: background .3s, color .3s, border .3s;
}

a:hover {
  border-bottom-color: #999;
}

hr {
  width: 10rem;
  border: none;
  border-top: thin solid #ddd;
  margin: 4rem auto;
  position: relative;
}

@media (min-width: 600px) {
  hr {
    margin: 5rem auto;
  }
}

@media (min-width: 900px) {
  hr {
    margin: 6rem auto;
  }
}

.Pagination {
  flex-wrap: wrap;
  justify-content: center;
  padding: 4rem 0;
  display: flex;
}

@media (min-width: 600px) {
  .Pagination {
    padding: 5rem 0;
  }
}

@media (min-width: 900px) {
  .Pagination {
    padding: 6rem 0;
  }
}

.Pagination-placeholder {
  width: 5rem;
  height: 5rem;
}

.Pagination-link {
  color: #111;
  width: 5rem;
  height: 5rem;
  background: #f7f7f7;
  border: thin solid #eaeaea;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding-bottom: .6rem;
  font-size: 2.7rem;
  text-decoration: none;
  display: flex;
}

.Pagination-link:hover {
  background: #eaeaea;
  border: thin solid #eaeaea;
}

.Pagination-link--prev {
  padding-right: .1rem;
}

.Pagination-link--next {
  padding-left: .1rem;
}

.Pagination-imageWrapper {
  width: 4rem;
  align-items: center;
  margin: 0 2rem;
  display: flex;
}

@media (min-width: 600px) {
  .Pagination-imageWrapper {
    width: 5rem;
  }
}

.Pagination-imageWrapper img {
  width: 100%;
}

.Pagination-homeLink {
  border: none;
  transition: transform .3s;
}

.Pagination-homeLink:hover {
  transform: rotate(10deg);
}

.Navigation {
  width: 100%;
  background: #f7f7f7;
  border-bottom: thin solid #ddd;
  padding: 2rem 0;
}

.Navigation .LanguageSelector {
  margin-top: 1rem;
}

@media (min-width: 600px) {
  .Navigation .LanguageSelector {
    margin: 0;
  }
}

.Navigation-content {
  width: 100%;
  max-width: 70rem;
  width: 100%;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (min-width: 600px) {
  .Navigation-content {
    align-items: center;
    padding-left: 3rem;
    padding-right: 3rem;
    display: flex;
  }
}

.Navigation-title {
  margin-right: auto;
}

.Navigation-link {
  color: #111;
  border-bottom: thin solid #ddd;
}

.Navigation-link:hover {
  border-bottom-color: #999;
}

.Navigation-separator {
  margin: 0 .5rem;
}

.LanguageSelector {
  font-size: .9em;
}

.LanguageSelector-button {
  align-items: center;
  display: flex;
}

.LanguageSelector-flag {
  width: 3rem;
  flex-shrink: 0;
  flex-basis: 3rem;
  margin-right: .8rem;
}

.LanguageSelector-icon {
  width: 2.4rem;
  vertical-align: middle;
}

@media (min-width: 600px) {
  .LanguageSelector-icon {
    width: 3rem;
  }
}

.LanguageSelector-text {
  margin-left: .6rem;
  display: inline-block;
  transform: translateY(-.1rem);
}

@media (min-width: 600px) {
  .LanguageSelector-text {
    margin-left: .8rem;
  }
}

.LanguageSelector-link--current {
  color: #777;
}

[data-inclusive-menu] {
  position: relative;
}

[data-inclusive-menu-opens] {
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  transition: opacity .3s;
}

[data-inclusive-menu-opens]:hover, [data-inclusive-menu-opens]:focus, [data-inclusive-menu-opens][aria-expanded="true"] {
  opacity: 1;
}

@media (min-width: 600px) {
  [data-inclusive-menu-opens] {
    opacity: .5;
  }
}

[data-inclusive-menu] [role="menu"] {
  width: auto;
  background: #f7f7f7;
  border: thin solid #ddd;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 1rem);
  left: 0;
}

@media (min-width: 600px) {
  [data-inclusive-menu] [role="menu"] {
    left: auto;
    right: 0;
  }
}

[data-inclusive-menu] [role^="menuitem"] {
  white-space: nowrap;
  width: 20.5rem;
  height: 3.5rem;
  border: none;
  align-items: center;
  padding: 0 .8rem;
  line-height: 0;
  display: flex;
}

[data-inclusive-menu] [role^="menuitem"]:hover, [data-inclusive-menu] [role^="menuitem"]:focus {
  background: #eaeaea;
}

@media (min-width: 600px) {
  [data-inclusive-menu] [role^="menuitem"] {
    width: 24rem;
  }
}

.Home {
  background: #fff;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .Home {
    display: block;
  }
}

@media (max-height: 1200px) {
  .Home {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }
}

@media (max-height: 1200px) and (-ms-high-contrast: none), (max-height: 1200px) and (-ms-high-contrast: active) {
  .Home {
    min-height: unset;
  }
}

.Home-top {
  background: #f7f7f7;
  border-bottom: thin solid #ddd;
  margin-bottom: 3rem;
  padding: 3rem 0 2.5rem;
}

.Home-topContent .LanguageSelector {
  margin-top: 1rem;
}

@media (min-width: 600px) {
  .Home-topContent .LanguageSelector {
    margin: 0;
  }

  .Home-topContent {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.Home-title {
  margin-bottom: .5rem;
  font-size: 3rem;
}

.Home-author {
  color: #777;
}

.Home-text {
  margin-bottom: 1em;
}

.Home-dedication {
  color: #777;
  margin-top: 3rem;
}

@media (min-width: 600px) {
  .Home-dedication {
    margin-top: 5rem;
  }
}

.Home-start {
  color: #111;
  border: none;
  margin: 3rem auto 0;
  transition: transform .3s;
  display: block;
  position: relative;
  transform: translate3d(0, 1rem, 0);
}

.Home-start:hover {
  transform: translate3d(0, 0, 0);
}

.Home-startImage {
  opacity: .9;
  width: 100%;
  height: auto;
  transition: opacity .3s;
  display: block;
}

.Home-start:hover .Home-startImage {
  opacity: 1;
}

.Home-startText {
  z-index: 1;
  font-size: 2rem;
  position: absolute;
  top: 45%;
  left: 30%;
  transform: rotate(6deg);
}

@media (min-width: 600px) {
  .Home-startText {
    font-size: 2.6rem;
  }
}

.About {
  padding-bottom: 4rem;
}

@media (min-width: 600px) {
  .About {
    padding-bottom: 5rem;
  }
}

@media (min-width: 900px) {
  .About {
    padding-bottom: 6rem;
  }
}

.About p {
  margin-bottom: 1em;
  font-size: 1.8rem;
  line-height: 1.4em;
}

@media (min-width: 900px) {
  .About p {
    font-size: 2rem;
  }
}

.About h1, .About h2 {
  margin: 4rem 0 2rem;
  font-size: 2.5rem;
}

@media (min-width: 600px) {
  .About h1, .About h2 {
    margin-top: 5rem;
  }
}

@media (min-width: 900px) {
  .About h1, .About h2 {
    margin-top: 6rem;
  }
}

.About h3 {
  margin: 2rem 0 1rem;
  font-size: 2.2rem;
}

.About img {
  display: block;
}

.About-drawings {
  margin-bottom: 1em;
}

@media (min-width: 900px) {
  .About-drawings {
    width: 150%;
    justify-content: space-between;
    margin-left: -25%;
    display: flex;
  }
}

.About-drawings img {
  width: 100%;
}

@media (min-width: 900px) {
  .About-drawing {
    width: calc(50% - 1rem);
    max-width: calc(50% - 1rem);
    flex-basis: calc(50% - 1rem);
  }
}

.About-signature {
  color: #aaa;
  text-align: right;
  margin-top: 10rem;
  font-size: .8em;
  line-height: 1.4em;
}

img.About-monkey {
  height: 1em;
  width: auto;
  display: inline;
  transform: translateY(.2em);
}

.Footer {
  width: 100%;
  color: #777;
  background: #f7f7f7;
  border-top: thin solid #ddd;
  padding: 4rem 0;
  font-size: 1.6rem;
  line-height: 1.4em;
}

@media (min-width: 600px) {
  .Footer {
    padding: 5rem 0;
  }
}

@media (min-width: 900px) {
  .Footer {
    padding: 6rem 0;
  }
}

.Footer a {
  margin-top: .5rem;
}

.Footer-nav {
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem 0 2rem;
  display: inline-flex;
}

.Footer-license {
  font-size: 1.2rem;
}

.Footnote {
  margin-top: 4rem;
  font-size: .75em;
}

.Footnote h2 {
  clip: rect(0 0 0 0);
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.Footnote-index {
  margin-right: .5rem;
}

.Sentence {
  text-align: center;
  margin-bottom: 3rem;
}

@media (min-width: 600px) {
  .Sentence {
    text-align: left;
  }
}

.Sentence-imageWrapper {
  max-width: 4rem;
  vertical-align: middle;
  align-items: center;
  margin: .2rem .5rem;
  display: inline-flex;
}

.Sentence-imageWrapper:first-child {
  margin-left: 0;
}

@media (min-width: 600px) {
  .Sentence-imageWrapper {
    max-width: 5rem;
    margin: 1rem;
  }
}

@media (min-width: 900px) {
  .Sentence-imageWrapper {
    max-width: 6rem;
  }
}

.Sentence-image {
  max-height: 4rem;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .Sentence-image {
    width: 100%;
  }
}

@media (min-width: 600px) {
  .Sentence-image {
    max-height: 5rem;
  }
}

@media (min-width: 900px) {
  .Sentence-image {
    max-height: 6rem;
  }
}

.Sentence-imageWrapper--lg {
  max-width: 6rem;
}

@media (min-width: 900px) {
  .Sentence-imageWrapper--lg {
    max-width: 9rem;
  }
}

.Sentence-imageWrapper--lg .Sentence-image {
  max-height: 6rem;
}

@media (min-width: 900px) {
  .Sentence-imageWrapper--lg .Sentence-image {
    max-height: 9rem;
  }
}

.Sentence-imageWrapper--xl {
  max-width: 9rem;
}

@media (min-width: 900px) {
  .Sentence-imageWrapper--xl {
    max-width: 11rem;
  }
}

.Sentence-imageWrapper--xl .Sentence-image {
  max-height: 9rem;
}

@media (min-width: 900px) {
  .Sentence-imageWrapper--xl .Sentence-image {
    max-height: 11rem;
  }
}

.Sentence-imageWrapper--xwide {
  max-width: 22rem;
}

@media (min-width: 900px) {
  .Sentence-imageWrapper--xwide {
    max-width: 25rem;
  }
}

.Sentence-imageWrapper--xwide .Sentence-image {
  max-height: 12rem;
}

@media (min-width: 900px) {
  .Sentence-imageWrapper--xwide .Sentence-image {
    max-height: 14rem;
  }
}

.LetterOne {
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: Patrick Hand SC, Pangolin, Helvetica, Arial, sans-serif;
  font-size: 2.3rem;
  line-height: 1.1em;
}

@media (min-width: 600px) {
  .LetterOne {
    padding-top: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    font-size: 2.5rem;
  }
}

@media (min-width: 900px) {
  .LetterOne {
    padding-top: 6rem;
  }

  .LetterOne-blocks {
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
  }
}

.LetterOne-title {
  text-align: center;
  margin-bottom: 5rem;
}

.LetterOne-block {
  margin-bottom: 6rem;
}

@media (min-width: 600px) {
  .LetterOne-block {
    margin-bottom: 8rem;
  }
}

@media (min-width: 900px) {
  .LetterOne-block {
    width: calc(50% - 1.5rem);
    flex-basis: calc(50% - 1.5rem);
  }
}

.LetterOne-block:last-child {
  margin-bottom: 0;
}

@media (min-width: 600px) and (max-width: 899px) {
  .LetterOne-block--two, .LetterOne-block--three {
    align-items: center;
    display: flex;
  }

  .LetterOne-block--two .LetterOne-text, .LetterOne-block--three .LetterOne-text {
    min-width: 18rem;
    text-align: left;
    flex-basis: 18rem;
  }

  .LetterOne-block--two .LetterOne-image, .LetterOne-block--three .LetterOne-image {
    width: calc(100% - 20rem);
  }

  .LetterOne-block--two .LetterOne-text {
    margin-left: 2rem;
  }

  .LetterOne-block--three .LetterOne-text {
    margin-right: 2rem;
  }
}

@media (min-width: 900px) {
  .LetterOne-block--three .LetterOne-text {
    max-width: 90%;
    margin: 0 0 1rem;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .LetterOne-blockThreeTextWrapper {
    width: 20rem;
    flex-basis: 20rem;
  }

  .LetterOne-block--one .LetterOne-text, .LetterOne-block--four .LetterOne-text {
    text-align: center;
    max-width: 40rem;
  }
}

.LetterOne-block--two {
  flex-direction: row-reverse;
}

.LetterOne-text {
  margin-bottom: 1rem;
}

@media (min-width: 600px) {
  .LetterOne-text {
    max-width: 60rem;
    margin: 0 auto 1rem;
    position: relative;
  }
}

.LetterOne-image {
  margin: 0 auto;
  display: block;
}

@media (min-width: 600px) {
  .LetterOne-image {
    max-width: 56rem;
  }
}

@media (min-width: 900px) {
  .LetterOne-image {
    max-width: 100%;
  }
}

.LetterOne-image--one {
  width: 100%;
  max-width: 32rem;
}

@media (min-width: 600px) {
  .LetterOne-image--one {
    width: auto;
  }
}

@media (min-width: 900px) {
  .LetterOne-image--one {
    max-width: 100%;
    max-height: 30rem;
  }
}

.LetterOne-inscription {
  margin-bottom: 1rem;
  font-size: .8em;
  line-height: 1.1em;
}

@media (min-width: 600px) and (max-width: 899px) {
  .LetterOne-block--four .LetterOne-inscription {
    text-align: center;
  }
}

.LetterTwo {
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: Patrick Hand SC, Pangolin, Helvetica, Arial, sans-serif;
  font-size: 2.3rem;
  line-height: 1.1em;
}

@media (min-width: 600px) {
  .LetterTwo {
    padding-top: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    font-size: 2.5rem;
  }
}

@media (min-width: 900px) {
  .LetterTwo {
    padding-top: 6rem;
  }
}

@media (min-width: 600px) {
  .LetterTwo .Sentence {
    margin-bottom: 1rem;
  }
}

.LetterTwo .Sentence:first-child {
  margin-bottom: 5rem;
}

.LetterTwo .Sentence:last-child {
  margin-top: 7rem;
}

@media (min-width: 600px) {
  .LetterTwo .Sentence:last-child {
    margin-top: 5rem;
    margin-left: 10rem;
  }
}

.Sentence-imageWrapper--window {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 600px) {
  .Sentence-imageWrapper--window {
    margin-top: 0;
  }
}

.LetterThree {
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: Patrick Hand SC, Pangolin, Helvetica, Arial, sans-serif;
  font-size: 2.3rem;
  line-height: 1.1em;
}

@media (min-width: 600px) {
  .LetterThree {
    padding-top: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    font-size: 2.5rem;
  }
}

@media (min-width: 900px) {
  .LetterThree {
    padding-top: 6rem;
  }
}

@media (max-width: 599px) {
  .LetterThree {
    text-align: center;
    font-size: 2rem;
  }
}

.LetterThree-stamp {
  vertical-align: middle;
  max-width: 8rem;
}

@media (min-width: 600px) {
  .LetterThree-stamp {
    max-width: 10rem;
  }
}

.LetterThree-stamp--tall {
  max-width: 6rem;
}

@media (min-width: 600px) {
  .LetterThree-stamp--tall {
    max-width: 9rem;
  }
}

.LetterThree-stamp--square {
  max-width: 7rem;
}

@media (min-width: 600px) {
  .LetterThree-stamp--square {
    max-width: 12rem;
  }
}

.LetterThree-stamp--landscape {
  max-width: 11rem;
}

@media (min-width: 600px) {
  .LetterThree-stamp--landscape {
    max-width: 13rem;
  }
}

.LetterThree-block {
  margin-bottom: 3rem;
}

@media (min-width: 600px) {
  .LetterThree-block {
    margin-bottom: 4rem;
  }
}

.LetterThree-block--double {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 600px) {
  .LetterThree-block--double {
    justify-content: flex-end;
  }
}

@media (max-width: 599px) {
  .LetterThree-block--double .LetterThree-stamp {
    max-width: 9rem;
  }
}

.LetterThree-doubleItem {
  max-width: 15rem;
  flex-wrap: wrap;
  flex-basis: calc(100% - 1rem);
  justify-content: center;
  align-items: center;
  display: flex;
}

.LetterThree-doubleItem:first-child {
  margin-right: 2rem;
}

@media (min-width: 600px) {
  .LetterThree-doubleItem {
    max-width: 100%;
    flex-basis: 50%;
  }
}

.LetterThree-doubleItemText {
  flex-basis: 100%;
}

@media (min-width: 600px) {
  .LetterThree-doubleItemText {
    text-align: right;
    flex-basis: 50%;
    padding-right: 1rem;
  }

  .LetterThree-doubleItemText--narrow {
    flex-basis: 35%;
  }
}

.LetterThree-doubleItemTextBottom {
  flex-basis: 100%;
}

@media (min-width: 600px) {
  .LetterThree-doubleItemTextBottom {
    text-align: left;
    flex-basis: auto;
    padding-left: .5rem;
  }

  .LetterThree-block--two {
    text-align: right;
    padding-right: 10%;
  }

  .LetterThree-block--four {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .LetterThree-blockFourStamps {
    margin-left: 2rem;
  }
}

.LetterThree-bottom {
  margin: 4rem auto 0;
}

@media (min-width: 600px) {
  .LetterThree-bottom {
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    display: flex;
  }
}

@media (min-width: 900px) {
  .LetterThree-bottom {
    margin-top: 6rem;
  }
}

.LetterThree-bottomImages {
  max-width: 32rem;
  margin: 1rem auto 0;
  position: relative;
}

@media (min-width: 600px) {
  .LetterThree-bottomImages {
    margin: 0;
  }
}

.LetterThree-stamp--bottom {
  z-index: 0;
  position: absolute;
  top: 10%;
  right: 10%;
}

.LetterThree-tadija {
  z-index: 0;
  width: 100%;
  position: relative;
}

.LetterFour {
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: Patrick Hand SC, Pangolin, Helvetica, Arial, sans-serif;
  font-size: 2.3rem;
  line-height: 1.1em;
}

@media (min-width: 600px) {
  .LetterFour {
    padding-top: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    font-size: 2.5rem;
  }
}

@media (min-width: 900px) {
  .LetterFour {
    padding-top: 6rem;
  }
}

.LetterFour-date {
  text-align: right;
  margin-bottom: 5rem;
}

.LetterFour-header {
  margin-bottom: 5rem;
}

.LetterFour-lastSentence {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.LetterFour-lastSentence img {
  margin-top: 1rem;
}

@media (min-width: 600px) {
  .LetterFour-lastSentence img {
    max-width: 50%;
  }
}

.LetterFour-lastSentenceBlock {
  flex-basis: calc(50% - 1.5rem);
}

.LetterFour-blocks {
  margin-top: 10rem;
}

@media (min-width: 600px) {
  .LetterFour-blocks {
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
  }
}

.LetterFour-block {
  margin-bottom: 6rem;
}

@media (min-width: 600px) {
  .LetterFour-block {
    flex-basis: calc(50% - 1.5rem);
    margin-bottom: 8rem;
  }
}

@media (min-width: 900px) {
  .LetterFour-block {
    flex-basis: calc(50% - 2.5rem);
  }
}

.LetterFour-block:last-child {
  margin-bottom: 0;
}

@media (min-width: 600px) {
  .LetterFour-block--three {
    margin-bottom: 0;
  }
}

.LetterFour-image {
  margin: 2rem auto 0;
  display: block;
}

@media (min-width: 900px) {
  .LetterFour-image {
    max-width: 100%;
  }
}

.LetterFour-block--two .LetterFour-image {
  margin-bottom: 2rem;
}

.LetterFive {
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: Patrick Hand SC, Pangolin, Helvetica, Arial, sans-serif;
  font-size: 2.3rem;
  line-height: 1.1em;
}

@media (min-width: 600px) {
  .LetterFive {
    padding-top: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    font-size: 2.5rem;
  }
}

@media (min-width: 900px) {
  .LetterFive {
    padding-top: 6rem;
  }
}

.LetterFive-date {
  text-align: right;
  margin-bottom: 2rem;
}

.LetterFive-header {
  margin-bottom: 2rem;
}

.LetterFive-top {
  margin-bottom: 4rem;
}

@media (min-width: 600px) {
  .LetterFive-top {
    align-items: center;
    display: flex;
  }
}

.LetterFive-topText {
  flex-shrink: 0;
  margin-bottom: 1rem;
}

@media (min-width: 600px) {
  .LetterFive-topText {
    margin-right: 2rem;
  }
}

.LetterFive-topImage {
  width: 100%;
}

.LetterFive-bottom {
  margin-bottom: 4rem;
}

@media (min-width: 600px) {
  .LetterFive-bottom {
    flex-flow: wrap;
    display: flex;
  }

  .LetterFive-bottomLeft {
    max-width: calc(40% - 2rem);
    width: calc(40% - 2rem);
    flex-basis: calc(40% - 2rem);
    margin-right: 2rem;
  }
}

.LetterFive-bottomText {
  margin-bottom: 1rem;
}

@media (min-width: 600px) {
  .LetterFive-bottomText {
    margin-bottom: 2rem;
  }

  .LetterFive-bottomImageSmall {
    display: none;
  }
}

.LetterFive-bottomImageLargeWrapper {
  margin: 2rem 0;
  display: none;
}

@media (min-width: 600px) {
  .LetterFive-bottomImageLargeWrapper {
    max-width: 60%;
    width: 60%;
    flex-basis: 60%;
    display: block;
  }
}

.LetterFive-bottomImageLarge {
  width: 100%;
}

.LetterFive-signature {
  text-align: right;
}

.Response {
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (min-width: 600px) {
  .Response {
    padding-top: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media (min-width: 900px) {
  .Response {
    padding-top: 6rem;
  }
}

.Response h1 {
  margin-bottom: 1em;
  font-size: 1.5em;
}

.Response p {
  text-indent: 2em;
  margin-bottom: .5em;
  line-height: 1.4em;
}

.Response-date {
  text-indent: 2em;
  margin-bottom: 3rem;
}

.Response-signature {
  text-indent: 2em;
  margin-top: 2em;
}

p.Response-center {
  text-indent: 0;
  text-align: center;
}

/*# sourceMappingURL=app.css.map */
