.Sentence {
  margin-bottom: 3rem;
  text-align: center;

  @include md {
    text-align: left;
  }
}

.Sentence-imageWrapper {
  align-items: center;
  display: inline-flex;
  margin: 0.2rem 0.5rem;
  max-width: 4rem;
  vertical-align: middle;

  &:first-child {
    margin-left: 0;
  }

  @include md {
    margin: 1rem;
    max-width: 5rem;
  }

  @include lg {
    max-width: 6rem;
  }
}

.Sentence-image {
  max-height: 4rem;

  @include ie {
    width: 100%;
  }

  @include md {
    max-height: 5rem;
  }

  @include lg {
    max-height: 6rem;
  }
}

.Sentence-imageWrapper--lg {
  max-width: 6rem;

  @include lg {
    max-width: 9rem;
  }

  .Sentence-image {
    max-height: 6rem;

    @include lg {
      max-height: 9rem;
    }
  }
}

.Sentence-imageWrapper--xl {
  max-width: 9rem;

  @include lg {
    max-width: 11rem;
  }

  .Sentence-image {
    max-height: 9rem;

    @include lg {
      max-height: 11rem;
    }
  }
}

.Sentence-imageWrapper--xwide {
  max-width: 22rem;

  @include lg {
    max-width: 25rem;
  }

  .Sentence-image {
    max-height: 12rem;

    @include lg {
      max-height: 14rem;
    }
  }
}
