.LanguageSelector {
  font-size: 0.9em;
}

.LanguageSelector-button {
  display: flex;
  align-items: center;
}

.LanguageSelector-flag {
  width: 3rem;
  flex-basis: 3rem;
  margin-right: 0.8rem;
  flex-shrink: 0;
}

.LanguageSelector-icon {
  width: 2.4rem;
  vertical-align: middle;

  @include md {
    width: 3rem;
  }
}

.LanguageSelector-text {
  margin-left: 0.6rem;
  display: inline-block;
  transform: translateY(-0.1rem);

  @include md {
    margin-left: 0.8rem;
  }
}

.LanguageSelector-link--current {
  color: $gray;
}

[data-inclusive-menu] {
  position: relative;
}

[data-inclusive-menu-opens] {
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  transition: opacity 300ms;

  &:hover,
  &:focus,
  &[aria-expanded="true"] {
    opacity: 1;
  }
  @include md {
    opacity: 0.5;
  }
}

[data-inclusive-menu] [role="menu"] {
  background: $gray-light;
  border: thin solid $gray-border;
  left: 0;
  top: calc(100% + 1rem);
  position: absolute;
  width: auto;
  flex-direction: column;

  @include md {
    left: auto;
    right: 0;
  }
}

[data-inclusive-menu] [role^="menuitem"] {
  align-items: center;
  border: none;
  display: flex;
  padding: 0 0.8rem;
  white-space: nowrap;
  width: 20.5rem;
  line-height: 0;
  height: 3.5rem;

  &:hover {
    background: $gray-hover;
  }

  &:focus {
    background: $gray-hover;
  }

  @include md {
    width: 24rem;
  }
}
