.Navigation {
  width: 100%;
  border-bottom: thin solid $gray-border;
  background: $gray-light;
  padding: 2rem 0;

  .LanguageSelector {
    margin-top: 1rem;

    @include md {
      margin: 0;
    }
  }
}

.Navigation-content {
  @include container;
  margin: 0 auto;
  width: 100%;

  @include md {
    display: flex;
    align-items: center;
  }
}

.Navigation-title {
  margin-right: auto;
}

.Navigation-link {
  @include link;
}

.Navigation-separator {
  margin: 0 0.5rem;
}
