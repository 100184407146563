// Colors
$white: #fff;
$black: #111;
$blue: #3498db;
$gray: #777;
$gray-light: #f7f7f7;
$gray-border: #ddd;
$gray-hover: darken($gray-light, 5);

// Fonts
$font-family-serif: 'Roboto Slab', Helvetica, Arial, sans-serif;
$font-family-handwriting: 'Patrick Hand SC', Pangolin, Helvetica, Arial, sans-serif;

$font-weight-serif: 300;



// Timings
