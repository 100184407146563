.LetterFive {
  @include letter;
}

.LetterFive-date {
  text-align: right;
  margin-bottom: 2rem;
}

.LetterFive-header {
  margin-bottom: 2rem;
}

.LetterFive-top {
  margin-bottom: 4rem;

  @include md {
    display: flex;
    align-items: center;
  }
}

.LetterFive-topText {
  flex-shrink: 0;
  margin-bottom: 1rem;

  @include md {
    margin-right: 2rem;
  }
}

.LetterFive-topImage {
  width: 100%;
}

.LetterFive-bottom {
  margin-bottom: 4rem;

  @include md {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.LetterFive-bottomLeft {
  @include md {
    flex-basis: calc(40% - 2rem);
    max-width: calc(40% - 2rem);
    width: calc(40% - 2rem);
    margin-right: 2rem;
  }
}

.LetterFive-bottomText {
  margin-bottom: 1rem;

  @include md {
    margin-bottom: 2rem;
  }
}

.LetterFive-bottomImageSmall {
  @include md {
    display: none;
  }
}

.LetterFive-bottomImageLargeWrapper {
  display: none;
  margin: 2rem 0;

  @include md {
    flex-basis: 60%;
    max-width: 60%;
    width: 60%;
    display: block;
  }
}

.LetterFive-bottomImageLarge {
  width: 100%;
}

.LetterFive-signature {
  text-align: right;
}
