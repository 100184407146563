.Footer {
  width: 100%;
  border-top: thin solid $gray-border;
  background: $gray-light;
  padding: 4rem 0;
  color: $gray;
  line-height: 1.4em;
  font-size: 1.6rem;

  @include md {
    padding: 5rem 0;
  }

  @include lg {
    padding: 6rem 0;
  }

  a {
    margin-top: 0.5rem;
  }
}

.Footer-nav {
  margin: 1rem 0 2rem;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.Footer-license {
  font-size: 1.2rem;
}
