// Container

@mixin container {
  margin: 0 auto;
  width: 100%;
  max-width: 70rem;
  padding-left: 2rem;
  padding-right: 2rem;

  @include md {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@mixin letter {
  @include container;
  @include handwritten-font;
  padding-top: 3rem;

  @include md {
    padding-top: 5rem;
  }

  @include lg {
    padding-top: 6rem;
  }
}

@mixin response {
  @include container;
  padding-top: 3rem;

  @include md {
    padding-top: 5rem;
  }

  @include lg {
    padding-top: 6rem;
  }
}

@mixin typewriter-font {
  font-family: $font-family-serif;
  font-weight: $font-weight-serif;
  font-size: 1.8rem;
  line-height: 1.4em;

  @include md {
    font-size: 2rem;
  }

  @include lg {
    font-size: 2.2rem;
  }
}

@mixin handwritten-font {
  font-size: 2.3rem;
  font-family: $font-family-handwriting;
  line-height: 1.1em;

  @include md {
    font-size: 2.5rem;
  }
}

@mixin link {
  border-bottom: thin solid $gray-light;
  color: $black;
  border-bottom: thin solid #ddd;

  &:hover {
    border-bottom-color: #999;
  }
}


@mixin vh($value, $property: height) {
  #{ $property }: #{ $value }vh;
  #{ $property }: calc(var(--vh, 1vh) * #{ $value });
}


@mixin ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
