.Pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 4rem 0;

  @include md {
    padding: 5rem 0;
  }

  @include lg {
    padding: 6rem 0;
  }
}

.Pagination-placeholder {
  width: 5rem;
  height: 5rem;
}

.Pagination-link {
  align-items: center;
  color: $black;
  display: flex;
  text-decoration: none;
  justify-content: center;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  padding-bottom: 0.6rem;
  font-size: 2.7rem;
  background: $gray-light;
  border: thin solid $gray-hover;

  &:hover {
    border: thin solid $gray-hover;
    background: $gray-hover;
  }
}

.Pagination-link--prev {
  padding-right: 0.1rem;
}

.Pagination-link--next {
  padding-left: 0.1rem;
}

.Pagination-imageWrapper {
  width: 4rem;
  margin: 0 2rem;
  display: flex;
  align-items: center;

  @include md {
    width: 5rem;
  }

  img {
    width: 100%;
  }
}

.Pagination-homeLink {
  border: none;
  transition: transform 300ms;

  &:hover {
    transform: rotate(10deg);
  }
}
